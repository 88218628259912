import React, { useState } from "react"
import { Container, Collapse, Navbar, NavbarToggler } from "reactstrap"
import Link from "./link"
import Navigation from "reactstrap-json-nav"
import navigation from "../data/navigation"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const StyledNavbar = styled((props) => <Navbar {...props} />)`
  /* position: sticky;
  top: 0;
  z-index: 10; */
`

const Logo = styled(Img)``

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  const data = useStaticQuery(graphql`
    fragment logo on File {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    query logoQuery {
      logo: file(relativePath: { eq: "logo.png" }) {
        ...logo
      }
    }
  `)

  return (
    <StyledNavbar color="light" light expand="md">
      <Container>
        <Link to="/" className="navbar-brand">
          <Logo fixed={data.logo.childImageSharp.fixed} alt="Logo" />
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Navigation json={navigation} link={Link} activeClassName="active" />
        </Collapse>
      </Container>
    </StyledNavbar>
  )
}

export default Header
